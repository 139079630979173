<template>
  <div class="comparison-value">
    <div class="value">
      {{ formatValue(value) }}
    </div>
    <el-tooltip
      :content="'Previous period: ' + formatValue(previousValue)"
      placement="bottom"
    >
      <span class="delta-value" :style="comparisonStyleObject">
        {{ formattedText }}
      </span>
    </el-tooltip>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import {
  formatNumber,
  formatFractionAsPercentage,
} from "@/utils/helpFunctions";
import { currencyDividedValueFormatted } from "@/utils/currencyFormat";

const props = defineProps({
  value: Number,
  previousValue: Number,
  type: {
    type: String,
    validator: (t) => t === "number" || t === "cost",
  },
});

const difference = computed(() => {
  const { value, previousValue } = props;
  return previousValue === 0 ? 0 : (value - previousValue) / previousValue;
});

const formattedText = computed(() => {
  const prefix = difference.value > 0 ? "+" : difference.value < 0 ? "−" : "";
  const formattedNumber = formatFractionAsPercentage(
    Math.abs(difference.value).toFixed(2)
  );
  return `(${prefix}${formattedNumber})`;
});

const comparisonStyleObject = computed(() => {
  let color = "";
  if (difference.value > 0) {
    color = "#2f7b50";
  } else if (difference.value < 0) {
    color = "#d33131";
  } else {
    color = "black";
  }
  return { color };
});

const formatValue = (value) => {
  let formattedValue;
  switch (props.type) {
    case "number":
      formattedValue = formatNumber(value);
      break;
    case "cost":
      formattedValue = currencyDividedValueFormatted(value);
      break;
    default:
      formattedValue = value;
      break;
  }
  return formattedValue;
};
</script>
<style lang="scss" scoped>
.comparison-value {
  text-align: center;
  .value {
    font-weight: 400;
    font-size: 40px;
    line-height: 54px;
    margin-top: 55px;
    color: $color-dark-blue;
  }
  .delta-value {
    font-size: 18px;
    line-height: 25px;
    color: $color-green;
  }
}
</style>
